import ResultSet from "@/models/ResultSet"
import axios from "axios"
import UserGroup from "@/models/UserGroup"
import { useStore } from "vuex"

export default class UserGroupService {
  pageSize: number = 20
  store: any

  constructor() {
    this.store = useStore()
  }

  async getUserGroups(
    page: number = 0,
    sort: string,
    filter: string,
    companyId: string,
    pageSize?: number
  ): Promise<ResultSet<UserGroup>> {
    let queryParams = new URLSearchParams({
      size: (pageSize ?? this.pageSize).toString(),
      page: page.toString(),
      sort,
    })

    if (filter) {
      queryParams.append("filter", filter)
    }

    if (companyId) {
      queryParams.append("companyId", companyId)
    }

    const url = `usergroups?${queryParams.toString()}`

    if (!this.store.getters.hasUserGroupData(url)) {
      const res = await axios.get(url)
      await this.store.commit("setUserGroups", {
        url,
        data: new ResultSet<UserGroup>(res.data, this.pageSize),
      })
    }
    return this.store.getters.getUserGroupData(url)
  }

  getUserGroupById(userGroupId: string) {
    return axios.get("usergroups/" + userGroupId).then((res) => res.data)
  }

  postUserGroup(userGroup: any, companyId: string) {
    return axios.post("usergroups?companyId=" + companyId, userGroup).then((res) => res.data)
  }

  editUserGroup(userGroupId: string, userGroup: any) {
    return axios.put("usergroups/" + userGroupId, userGroup).then((res) => res.data)
  }

  deleteUserGroupById(userGroupId: string) {
    return axios.delete("usergroups/" + userGroupId).then((res) => res.data)
  }

  postUserToUserGroup(userGroupId: string, userId: string) {
    return axios
      .post("usergroups/" + userGroupId + "/users?userId=" + userId)
      .then((res) => res.data)
  }

  deleteUserOfUserGroup(userGroupId: string, userId: string) {
    return axios
      .delete("usergroups/" + userGroupId + "/users?userId=" + userId)
      .then((res) => res.data)
  }
}
