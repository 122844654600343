import { Label, SelectedLabel, WeekDay } from "@/models/Task"
import { addDays, format, isAfter, isWeekend } from "date-fns"
import { Ref } from "vue"
import { TaskAndChildren } from "@/components/tasks/types/TaskAndChildren"

export function calculateItemLabelSelectionState(
  itemLabels: Label[],
  companyLabels: Label[]
): SelectedLabel[][] {
  const selectedLabels: SelectedLabel[] = []

  companyLabels.forEach((label: Label) => {
    const selected = itemLabels?.find((item: Label) => item.id === label.id)
    const selectedLabel: SelectedLabel = { ...label, selected: !!selected }
    selectedLabels.push(selectedLabel)
  })

  const groupedLabels = groupLabelsByRanking(selectedLabels)
  const result: SelectedLabel[][] = [[]]
  groupedLabels.forEach((labelGroup) => result.push(labelGroup))
  return result
}

export function groupLabelsByRanking(labels: SelectedLabel[]): SelectedLabel[][] {
  const groupedLabels = []
  const group1: SelectedLabel[] = []
  const group2: SelectedLabel[] = []
  const group3: SelectedLabel[] = []
  const group4: SelectedLabel[] = []

  labels.forEach((label: any) => {
    if (label.ranking >= 70) {
      group1.push(label)
    } else if (label.ranking >= 60 && label.ranking < 70) {
      group2.push(label)
    } else if (label.ranking >= 50 && label.ranking < 60) {
      group3.push(label)
    } else if (label.ranking >= 40 && label.ranking < 50) {
      group4.push(label)
    }
  })

  groupedLabels.push(group1)
  groupedLabels.push(group2)
  groupedLabels.push(group3)
  groupedLabels.push(group4)

  return groupedLabels
}

export function getWorkdays(startingDate: Date, isNext: boolean, now: Date): WeekDay[] {
  let workdays: WeekDay[] = []
  let count = 0
  let _currentDate = new Date(
    startingDate.getFullYear(),
    startingDate.getMonth(),
    startingDate.getDate()
  )

  //added 7 days and weekend check for Lode to be able to work in the weekend
  while (count < (isWeekend(now) ? 7 : 5)) {
    // Check if the current day is a weekend (Saturday or Sunday)
    if (isWeekend(now)) {
      const dayName = format(_currentDate, "E dd-MM") // Get the full day name
      const date = format(_currentDate, "yyyy-MM-dd") // Get the date in 'yyyy-MM-dd' format

      workdays.push({ day: dayName, date, rawDate: _currentDate })
      count++
    } else if (!isWeekend(_currentDate)) {
      const dayName = format(_currentDate, "E dd-MM") // Get the full day name
      const date = format(_currentDate, "yyyy-MM-dd") // Get the date in 'yyyy-MM-dd' format

      workdays.push({ day: dayName, date, rawDate: _currentDate })
      count++
    }

    // Move to the next day (or previous day if isNext is false)
    _currentDate = addDays(_currentDate, isNext ? 1 : -1)
  }

  if (!isNext) {
    workdays = workdays.reverse()
  }

  // currentDate.value = workdays[0].rawDate

  return workdays
}

// Planned date cannot be after deadline so move deadline too
export const checkOrUpdateDeadline = (strPlannedDate: string, strDeadline: string): string => {
  if (strPlannedDate && strDeadline) {
    const plannedDate = new Date(strPlannedDate)
    const deadline = new Date(strDeadline)
    if (isAfter(plannedDate, deadline)) {
      let date = new Date(plannedDate.getTime())
      date.setHours(16)
      date.setMinutes(30)
      return date.toISOString()
    }
  }
  return strDeadline
}

export const normalizeToMidnight = (dateInput: string | Date): number | null => {
  const date = dateInput instanceof Date ? dateInput : new Date(dateInput)
  if (isNaN(date.getTime())) {
    return null // Return null if the date is invalid
  }
  date.setHours(0, 0, 0, 0) // Set time to midnight
  return date.getTime()
}

export const isToBeMarkedAsDeadline = (
  plannedDate: string,
  deadline: string,
  completed: boolean
) => {
  if (!plannedDate || !deadline || completed) {
    return false // If there's no planned date, no deadline, or the task is completed, it's not a deadline.
  }

  const plannedDateTime = normalizeToMidnight(plannedDate)
  const deadlineDateTime = normalizeToMidnight(deadline)
  const today = normalizeToMidnight(new Date(Date.now()))

  // Handle cases where the date normalization failed (null result)
  if (plannedDateTime === null || deadlineDateTime === null || today === null) {
    return false // If any date is invalid, return false to avoid errors.
  }

  return (
    plannedDateTime === deadlineDateTime || deadlineDateTime < today // Return true if planned date matches deadline or deadline has passed.
  )
}

export const createInterval = (weekScope: WeekDay[]): Interval => {
  return weekScope
    ? {
        start: weekScope[0].rawDate,
        end: new Date(
          weekScope[4].rawDate.getFullYear(),
          weekScope[4].rawDate.getMonth(),
          weekScope[4].rawDate.getDate(),
          23,
          59,
          59
        ),
      }
    : null
}

export const updateLastPlannedTask = (
  tasks: Ref<TaskAndChildren[]>,
  lastPlannedTask: Ref<TaskAndChildren>
) => {
  for (let i = 0; i < tasks.value.length; i++) {
    const notLastItem = i + 1 < tasks.value.length
    const isPlanned = !!tasks.value[i].task.plannedDate
    const isCompleted = !!tasks.value[i].task.isCompleted()
    const nextNotPlanned = notLastItem ? !tasks.value[i + 1].task.plannedDate : null
    const nextCompleted = notLastItem ? tasks.value[i + 1].task.isCompleted() : null

    if (notLastItem && isPlanned && !isCompleted && (nextNotPlanned || nextCompleted)) {
      lastPlannedTask.value = tasks.value[i]
      break
    }
  }
}
