import axios from "axios"
import TaskService from "@/services/TaskService"
import isDevelopment from "@/utils/utils"
import { ServerError } from "@/utils/server-error"
import User from "@/models/User"
import { Task } from "@/models/Task"

const taskService = new TaskService()

export default class TaskAssigneeService {
  async postAssignee(task: any, employee: any) {
    let url = `taskassignees?taskId=${task.id}&userId=${employee.id}`
    try {
      const response = await axios.post(url)
      taskService.realtimePublish(task)
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }

  async deleteAssignee(task: Task, employee: User) {
    let url = `taskassignees?taskId=${task.id}&userId=${employee.id}`
    const res = await axios.delete(url)
    //update realtime data
    taskService.realtimePublish(task)
    return res.data
  }

  editAssignee(taskId: string, userId: string, seen: boolean) {
    let url = `taskassignees?taskId=${taskId}&userId=${userId}&seen=${seen}`
    return axios.put(url).then((res) => res.data)
  }

  throwError = (error: any) => {
    if (error.response && isDevelopment()) {
      throw new ServerError(error.response.data.error, error.response.data.message)
    } else {
      throw new ServerError("Server error", "A server error occurred, please try again later.")
    }
  }
}
