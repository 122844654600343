import { TaskAssigneeId } from "./TaskAssigneeId"

export class TaskAssignee {
  id: TaskAssigneeId
  seen: boolean
  user: any

  constructor(taskAssigneeId: TaskAssigneeId, seen: boolean, user: any) {
    this.id = taskAssigneeId
    this.seen = seen
    this.user = user
  }
}
